// ** Initial State
const initialState = {
  dropdown: [],
  dropdownFormatted: [],
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedClient: null,
  addClientDetail: null,
  updateClientDetail: null,
  addClientContact: null,
  updateClientContact: null
}

const clients = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CLIENTS_DROPDOWN':
      return { 
        ...state, 
        dropdown: action.data,
        dropdownFormatted: action.data.map(item => ({
          label: item.name,
          value: item.id,
        }))
      }
    case 'GET_ALL_CLIENTS':
      return { ...state, allData: action.data }
    case 'FILTER_ALL_CLIENTS':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_CLIENT':
      return { ...state, selectedClient: action.selectedClient }
    case 'ADD_CLIENT':
      return { ...state }
      case 'DELETE_CLIENT':
        return { ...state }

        // Client Details
        case 'ADD_CLIENT_DETAIL':
          return { ...state, addClientDetail: action.data }
        case 'UPDATE_CLIENT_DETAIL':
          return { ...state, updateClientDetail: action.data }

        // Client Contacts
        case 'ADD_CLIENT_CONTACT':
          return { ...state, addClientContact: action.data }
        case 'UPDATE_CLIENT_CONTACT':
          return { ...state, updateClientContact: action.data }


    default:
      return { ...state }
  }
}
export default clients
