// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedHoliday: null
}

const holidays = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_HOLIDAYS':
      return { ...state, allData: action.data }
    case 'FILTER_ALL_HOLIDAYS':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_HOLIDAY':
      return { ...state, selectedHoliday: action.selectedHoliday }
    case 'ADD_HOLIDAY':
      return { ...state }
    case 'DELETE_HOLIDAY':
      return { ...state }
    default:
      return { ...state }
  }
}
export default holidays
