// ** Initial State
const initialState = {
  dropdown: [],
  dropdownUnique: [],
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedGrade: null
}

const grades = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_GRADES_DROPDOWN':
      return { ...state, dropdown: action.data }
    case 'GET_GRADES_DROPDOWN_UNIQUE':
      return { ...state, dropdownUnique: action.data }
    case 'GET_ALL_GRADES':
      return { ...state, allData: action.data }
    case 'FILTER_ALL_GRADES':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_GRADE':
      return { ...state, selectedGrade: action.selectedGrade }
    case 'ADD_GRADE':
      return { ...state }
    case 'DELETE_GRADE':
      return { ...state }
    default:
      return { ...state }
  }
}
export default grades
