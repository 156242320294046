// ** Initial State
const initialState = {
  dropdown: [],
  save: null,
}

const attendance = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_EMPLOYEES_ATTENDANCE_LIST':
      return { ...state, dropdown: action.data }
    case 'SAVE_ATTENDANCE':
      return { ...state, save: action.data }
    default:
      return { ...state }
  }
}
export default attendance
