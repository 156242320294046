// ** Initial State
const initialState = {
  
  data: [],
  total: 1,
  params: {},
  selectedPayslip: null
}

const payslips = (state = initialState, action) => {
  switch (action.type) {
    
    case 'FILTER_ALL_PAYSLIPS':
      return {
        ...state,
        data: action.data,
        total: action.totalRecords,
        params: action.params
      }
    case 'GET_PAYSLIP':
      return { ...state, selectedPayslip: action.selectedPayslip }
      
    default:
      return { ...state }
  }
}
export default payslips
