// ** Initial State
const initialState = {
  leaveSettingsDepartementDropdown: [],
  leaveSettingsEmployeeDropdown: [],
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedLeaveSetting: null,
  add: null,
  selectedLeaveSettingApplicableConfig: null,
  update: null
}

const leaveSettings = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_LEAVE_SETTINGS_DEPARTMENT_DROPDOWN':
      return { ...state, leaveSettingsDepartementDropdown: action.data }
    case 'GET_ALL_LEAVE_SETTINGS_EMPLOYEE_DROPDOWN':
      return { ...state, leaveSettingsEmployeeDropdown: action.data }
    case 'GET_ALL_LEAVE_SETTINGS':
      return { ...state, allData: action.data }
    case 'FILTER_ALL_LEAVE_SETTINGS':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_LEAVE_SETTING':
      return { ...state, selectedLeaveSetting: action.selectedLeaveSetting }
    case 'GET_LEAVE_SETTING_APPLICAPLE_CONFIG':
      return { ...state, selectedLeaveSettingApplicableConfig: action.selectedLeaveSettingApplicableConfig }
    case 'ADD_LEAVE_SETTING':
      return { ...state, add: action.data }
    case 'UPDATE_LEAVE_SETTING':
      return { ...state, update: action.data }
    case 'DELETE_LEAVE_SETTING':
      return { ...state }
    default:
      return { ...state }
  }
}
export default leaveSettings
