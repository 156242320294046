// ** Initial State
const initialState = {
  dropdown: [],
  data: [],
  total: 1,
  params: {},
  selectedRole: null
}

const roles = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ROLES_DROPDOWN':
      return { ...state, dropdown: action.data }
    case 'FILTER_ALL_ROLES':
      return {
        ...state,
        data: action.data,
        total: action.totalRecords,
        params: action.params
      }
    case 'GET_ROLE':
      return { ...state, selectedRole: action.selectedRole }
    case 'ADD_ROLE':
      return { ...state }
    case 'DELETE_ROLE':
      return { ...state }
    default:
      return { ...state }
  }
}
export default roles
