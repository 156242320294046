// ** Initial State
const initialState = {
  leaveTypesDepartementDropdown: [],
  leaveTypesEmployeeDropdown: [],
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedLeaveType: null
}

const leaveTypes = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_LEAVE_TYPES_DEPARTMENT_DROPDOWN':
      return { ...state, leaveTypesDepartementDropdown: action.data }
    case 'GET_ALL_LEAVE_TYPES_EMPLOYEE_DROPDOWN':
      return { ...state, leaveTypesEmployeeDropdown: action.data }
    case 'GET_ALL_LEAVE_TYPES':
      return { ...state, allData: action.data }
    case 'FILTER_ALL_LEAVE_TYPES':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_LEAVE_TYPE':
      return { ...state, selectedLeaveType: action.selectedLeaveType }
    case 'ADD_LEAVE_TYPE':
      return { ...state }
    case 'DELETE_LEAVE_TYPE':
      return { ...state }
    default:
      return { ...state }
  }
}
export default leaveTypes
