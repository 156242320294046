// ** Initial State
const initialState = {
  allData: [],
  selectedPrice: {},
  currentTab: 1
}

const pricing = (state = initialState, action) => {
  
  switch (action.type) {
    case 'GET_ALL_PRICINGS':
      return { ...state, allData: action.data }


    // No Action Reducers
    case 'SET_SELECTED_PRICE':
      return { ...state, selectedPrice: action.data }
    case 'SET_CURRENT_TAB':
      return { ...state, currentTab: action.data }
      
    default:
      return { ...state }
  }
}
export default pricing
