// ** Initial State
const initialState = {
  selectedSplitCompany: [],
  update: [],
  allCompanies: []
}

const splitCompany = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_SPLIT_COMPANY':
      return { ...state, selectedSplitCompany: action.selectedSplitCompany }
    case 'GET_ALL_SPLIT_COMPANIES':
      return { ...state,
        allCompanies: action.data.map(item => ({
          label: item.company_name,
          value: item.id,
        })) 
      }
    case 'UPDATE_SPLIT_COMPANY':
      return { ...state, update: action.selectedSplitCompany }
      
    default:
      return { ...state }
  }
}
export default splitCompany
