// ** Initial State
const initialState = {
  userAllowancesDropdown: [],
  userAllowancesPayroll: null,
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedUserAllowance: null,
  add: null,
  update: null
}

const userAllowances = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_ALLOWANCES_DROPDOWN':
      return { ...state, userAllowancesDropdown: action.data.dropdown, userAllowancesPayroll: action.data.payroll }
    case 'GET_ALL_USER_ALLOWANCES':
      return { ...state, allData: action.data }
    case 'FILTER_ALL_USER_ALLOWANCES':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_USER_ALLOWANCE':
      return { ...state, selectedUserAllowance: action.selectedUserAllowance }
      
    default:
      return { ...state }
  }
}
export default userAllowances
