// ** Initial State
const initialState = {
  selectedUserPassport: null,
  add: null,
  update: null
}

const userPassports = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_USER_PASSPORT':
      return { ...state, selectedUserPassport: action.selectedUserPassport }
      
    default:
      return { ...state }
  }
}
export default userPassports
