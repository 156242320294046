// ** Initial State
const initialState = {
  dropdown: [],
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedCpfAge: null
}

const cpfAges = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_CPF_AGES_DROPDOWN':
      return { ...state, dropdown: action.data }
    case 'GET_ALL_CPF_AGES':
      return { ...state, allData: action.data }
    case 'FILTER_ALL_CPF_AGES':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_CPF_AGE':
      return { ...state, selectedCpfAge: action.selectedCpfAge }
    case 'ADD_CPF_AGE':
      return { ...state }
    case 'DELETE_CPF_AGE':
      return { ...state }
    default:
      return { ...state }
  }
}
export default cpfAges
