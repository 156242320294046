// ** Initial State
const initialState = {
  dropdown: [],
  dropdownFormatted: [],
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedProject: null,
  selectedProjectTeam: null,
  addProjectTeam: null,
  updateProjectTeam: null,
  addProjectTeamMember: null,
  updateProjectTeamMember: null
}

const projects = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PROJECTS_DROPDOWN':
      return { 
        ...state, 
        dropdown: action.data,
        dropdownFormatted: action.data.map(item => ({
          label: item.name,
          value: item.id,
        }))
      }
    case 'GET_ALL_PROJECTS':
      return { ...state, allData: action.data }
    case 'FILTER_ALL_PROJECTS':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_PROJECT':
      return { ...state, selectedProject: action.selectedProject }
    case 'ADD_PROJECT':
      return { ...state }
      case 'DELETE_PROJECT':
        return { ...state }


        // Project Team
        case 'GET_PROJECT_TEAM':
          return { ...state, selectedProjectTeam: action.data }
        case 'ADD_PROJECT_TEAM':
          return { ...state, addProjectTeam: action.data }
        case 'UPDATE_PROJECT_TEAM':
          return { ...state, updateProjectTeam: action.data }

        // Project Team
        case 'ADD_PROJECT_TEAM':
          return { ...state, addProjectTeamMember: action.data }
        case 'UPDATE_PROJECT_TEAM':
          return { ...state, updateProjectTeamMember: action.data }

    default:
      return { ...state }
  }
}
export default projects
