// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedPackageModule: null
}

const packageModules = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_PACKAGE_MODULES':
      return { ...state, allData: action.data }
    case 'FILTER_ALL_PACKAGE_MODULES':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_PACKAGE_MODULE':
      return { ...state, selectedPackageModule: action.selectedPackageModule }
    case 'ADD_PACKAGE_MODULE':
      return { ...state }
    case 'DELETE_PACKAGE_MODULE':
      return { ...state }
    default:
      return { ...state }
  }
}
export default packageModules
