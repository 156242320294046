// ** Initial State
const initialState = {
  registerData: []
}

const register = (state = initialState, action) => {
  
  switch (action.type) {
    case 'SUBMIT_REGISTER':
      return { ...state, registerData: action.data }

    default:
      return { ...state }
  }
}
export default register
