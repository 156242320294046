// ** Initial State
const initialState = {
  dropdown: [],
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedCdacFund: null,
  addCdacFundRate: null,
  updateCdacFundRate: null
}

const cdacs = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CDAC_FUNDS_DROPDOWN':
      return { 
        ...state, 
        dropdown: action.data
      }
    case 'GET_ALL_CDAC_FUNDS':
      return { ...state, allData: action.data }
    case 'FILTER_ALL_CDAC_FUNDS':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_CDAC_FUND':
      return { ...state, selectedCdacFund: action.selectedCdacFund }
    case 'ADD_CDAC_FUND':
      return { ...state }
      case 'DELETE_CDAC_FUND':
        return { ...state }

        // CdacFund Rates
        case 'ADD_CDAC_FUND_RATE':
          return { ...state, addCdacFundRate: action.data }
        case 'UPDATE_CDAC_FUND_RATE':
          return { ...state, updateCdacFundRate: action.data }

    default:
      return { ...state }
  }
}
export default cdacs
