// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedSdl: null
}

const sdls = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_SDLS':
      return { ...state, allData: action.data }
    case 'FILTER_ALL_SDLS':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_SDL':
      return { ...state, selectedSdl: action.selectedSdl }
    case 'ADD_SDL':
      return { ...state }
    default:
      return { ...state }
  }
}
export default sdls
