// ** Initial State
const initialState = {
  dropdown: [],
  dropdownFormatted: [],
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedFunctionalGroup: null
}

const functionalGroups = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_FUNCTIONAL_GROUPS_DROPDOWN':
      return { 
        ...state, 
        dropdown: action.data,
        dropdownFormatted: action.data.map(item => ({
          label: item.functional_group,
          value: item.id,
        }))
      }
    case 'GET_ALL_FUNCTIONAL_GROUPS':
      return { ...state, allData: action.data }
    case 'FILTER_ALL_FUNCTIONAL_GROUPS':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_FUNCTIONAL_GROUP':
      return { ...state, selectedFunctionalGroup: action.selectedFunctionalGroup }
    case 'ADD_FUNCTIONAL_GROUP':
      return { ...state }
    case 'DELETE_FUNCTIONAL_GROUP':
      return { ...state }
    default:
      return { ...state }
  }
}
export default functionalGroups
