// ** Initial State
const initialState = {
  dropdown: [],
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedCpf: null,
  addCpfRate: null,
  updateCpfRate: null
}

const cpfs = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CPFS_DROPDOWN':
      return { 
        ...state, 
        dropdown: action.data
      }
    case 'GET_ALL_CPFS':
      return { ...state, allData: action.data }
    case 'FILTER_ALL_CPFS':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_CPF':
      return { ...state, selectedCpf: action.selectedCpf }
    case 'ADD_CPF':
      return { ...state }
      case 'DELETE_CPF':
        return { ...state }

        // Cpf Rates
        case 'ADD_CPF_RATE':
          return { ...state, addCpfRate: action.data }
        case 'UPDATE_CPF_RATE':
          return { ...state, updateCpfRate: action.data }

    default:
      return { ...state }
  }
}
export default cpfs
