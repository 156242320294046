// ** Initial State
const initialState = {
  dropdown: [],
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedEcFund: null,
  addEcFundRate: null,
  updateEcFundRate: null
}

const ecs = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_EC_FUNDS_DROPDOWN':
      return { 
        ...state, 
        dropdown: action.data
      }
    case 'GET_ALL_EC_FUNDS':
      return { ...state, allData: action.data }
    case 'FILTER_ALL_EC_FUNDS':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_EC_FUND':
      return { ...state, selectedEcFund: action.selectedEcFund }
    case 'ADD_EC_FUND':
      return { ...state }
      case 'DELETE_EC_FUND':
        return { ...state }

        // EcFund Rates
        case 'ADD_EC_FUND_RATE':
          return { ...state, addEcFundRate: action.data }
        case 'UPDATE_EC_FUND_RATE':
          return { ...state, updateEcFundRate: action.data }

    default:
      return { ...state }
  }
}
export default ecs
