// ** Initial State
const initialState = {
  dropdown: [],
  dropdownFormatted: [],
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedClass: null
}

const classes = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CLASSES_DROPDOWN':
      return { 
        ...state, 
        dropdown: action.data,
        dropdownFormatted: action.data.map(item => ({
          label: item.class,
          value: item.id,
        }))
      }
    case 'GET_ALL_CLASSES':
      return { ...state, allData: action.data }
    case 'FILTER_ALL_CLASSES':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_CLASS':
      return { ...state, selectedClass: action.selectedClass }
    case 'ADD_CLASS':
      return { ...state }
    case 'DELETE_CLASS':
      return { ...state }
    default:
      return { ...state }
  }
}
export default classes
