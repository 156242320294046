// ** Initial State
const initialState = {
  dropdown: [],
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedMbmFund: null,
  addMbmFundRate: null,
  updateMbmFundRate: null
}

const mbms = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_MBM_FUNDS_DROPDOWN':
      return { 
        ...state, 
        dropdown: action.data
      }
    case 'GET_ALL_MBM_FUNDS':
      return { ...state, allData: action.data }
    case 'FILTER_ALL_MBM_FUNDS':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_MBM_FUND':
      return { ...state, selectedMbmFund: action.selectedMbmFund }
    case 'ADD_MBM_FUND':
      return { ...state }
      case 'DELETE_MBM_FUND':
        return { ...state }

        // MbmFund Rates
        case 'ADD_MBM_FUND_RATE':
          return { ...state, addMbmFundRate: action.data }
        case 'UPDATE_MBM_FUND_RATE':
          return { ...state, updateMbmFundRate: action.data }

    default:
      return { ...state }
  }
}
export default mbms
