// ** Initial State
const initialState = {
  userDeductionsDropdown: [],
  userDeductionsPayroll: null,
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedUserDeduction: null,
  add: null,
  update: null
}

const userDeductions = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DEDUCTIONS_DROPDOWN':
      return { ...state, userDeductionsDropdown: action.data.dropdown, userDeductionsPayroll: action.data.payroll }
    case 'GET_ALL_USER_DEDUCTIONS':
      return { ...state, allData: action.data }
    case 'FILTER_ALL_USER_DEDUCTIONS':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_USER_DEDUCTION':
      return { ...state, selectedUserDeduction: action.selectedUserDeduction }
      
    default:
      return { ...state }
  }
}
export default userDeductions
