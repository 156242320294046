// ** Initial State
const initialState = {
  sendOtpEmail: '',
  response: null
}

const otp = (state = initialState, action) => {
  
  switch (action.type) {
    case 'VERIFY_EMAIL_OTP':
      return { ...state, response: action.data }
    case 'GENERATE_OTP':
      return { ...state, response: action.data }

    // No Actions
    case 'SEND_OTP_EMAIL':
      return { ...state, sendOtpEmail: action.data }
      
    default:
      return { ...state }
  }
}
export default otp
