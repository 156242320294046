// ** Initial State
const initialState = {
  leaveSettingsDropdown: [],
  allData: [],
  data: [],
  total: 1,
  params: {},
  add: null
}

const userAccesses = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_ACCESSES_DROPDOWN':
      return { ...state, leaveSettingsDropdown: action.data }
    case 'GET_ALL_USER_ACCESSES':
      return { ...state, allData: action.data }
    case 'FILTER_ALL_USER_ACCESSES':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }

    default:
      return { ...state }
  }
}
export default userAccesses
